<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex xs12 text-left px-8>
        <span style="color: #000; font-size: 20px; font-family: poppinsbold"
          >Booking List</span
        >
      </v-flex>
      <v-flex px-3 xs12 sm12 md12 lg12 xl12 text-center>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 md3 pa-5 v-if="dateProp !== 'dateWisePrograms'">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Booking Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="menu11 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 pa-5 v-if="dateProp !== 'dateWisePrograms'">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Booking Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 sm6 md3 pa-5 v-if="dateProp == 'dateWisePrograms'">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title @input="menu1 = false">
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl4 pa-5>
            <v-select
              v-model="programme"
              :items="programs"
              item-text="name"
              item-value="value"
              outlined
              dense
              label="Program"
            ></v-select>
          </v-flex>
          <v-flex v-if="programme" xs12 sm6 md3 lg3 xl3 pa-5>
            <v-select
              v-model="slotDetail"
              :items="slotItems"
              item-text="startTime"
              item-value="_id"
              item-color="#8d8d8d"
              color="#8d8d8d"
              outlined
              dense
              label="Slots"
            >
              <template v-slot:selection="{ item }">
                <span>
                  {{ formatTime(item.startTime) }} -
                  {{ formatTime(item.endTime) }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span>
                  {{ formatTime(item.startTime) }} -
                  {{ formatTime(item.endTime) }}
                </span>
              </template>
            </v-select>
          </v-flex>
          <v-flex md3 v-if="booking.length > 0">
            <v-layout wrap justify-end pt-6>
              <v-flex
                xs12
                sm6
                md6
                lg6
                xl6
                v-if="dateProp == 'dateWisePrograms'"
              >
                <a
                  target="_blank"
                  :href="
                    'https://api.mudumalaitigerreserve.com/booking/admingetlistpdf?date=' +
                    date +
                    '&programme=' +
                    programme
                  "
                  class="downlink"
                >
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68d389;
                    "
                  >
                    Download Pdf</span
                  >
                </a>
              </v-flex>
              <v-flex xs12 sm6 md6 lg6 xl6 v-else>
                <a
                  target="_blank"
                  :href="
                    'https://api.mudumalaitigerreserve.com/booking/admingetlistpdf?fromDate=' +
                    fromDate +
                    '&toDate=' +
                    toDate +
                    '&programme=' +
                    programme
                  "
                  class="downlink"
                >
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68d389;
                    "
                  >
                    Download Pdf</span
                  >
                </a>
              </v-flex>
              <!-- <v-flex xs12 sm6 md6 lg6 xl6 align-self-end>
                <download-excel :data="json_data" :fields="json_fields">
                  <span
                    style="
                      font-size: 16px;
                      font-family: poppinssemibold;
                      color: #68d389;
                      cursor: pointer;
                    "
                  >
                    Download Excel</span
                  >
                </download-excel>
              </v-flex> -->
            </v-layout>
          </v-flex>
          <v-flex xs12 v-if="summaryList" pa-4>
            <v-card outlined tile v-if="summaryList.length > 0">
              <v-layout wrap>
                <v-flex xs12 pa-2>
                  <span class="poppinsbold" style="font-size: 20px"
                    >Summary</span
                  >
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  pa-2
                  text-left
                  v-for="(item, i) in summaryList"
                  :key="i"
                >
                  <span class="poppinssemibold" v-if="item.programme.progName">
                    {{ item.programme.progName }} -
                    <span class="poppinsregular" style="color: red">
                      {{ item.ticketCount }} Tickets &
                      {{ item.guestCount }} Guests
                    </span>
                  </span>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start px-5>
          <v-flex v-if="booking.length > 0" xs12 pt-4 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Bookings</span
            >
            <BookingData
              :booking="booking"
              :pages="pages"
              @stepper="winStepper"
            />
          </v-flex>
          <v-flex xs12 align-self-center pa-4 pt-8>
            <v-divider></v-divider>
          </v-flex>
          <v-flex v-if="reservationData.length > 0" xs12 pt-4 text-left>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Reservations</span
            >
            <Reservation :reservationData="reservationData" />
          </v-flex>
          <v-flex v-else text-left pa-4>
            <span style="color: #000; font-size: 18px; font-family: poppinsbold"
              >Reservations</span
            >
            <br />
            <br />
            <span class="textField2"> No Reservations Found !! </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import BookingData from "./bookingData";
import Reservation from "./reservation";
export default {
  components: {
    Reservation,
    BookingData,
  },
  props: ["dateProp"],
  data() {
    return {
      booking: [],
      reservationData: [],
      currentPage: 1,
      pages: 0,
      count: 60,
      appLoading: false,
      programme: null,
      slotDetail: null,
      excel: [],
      programeData: [],
      details: [],
      slotDetails: [],
      guest: [],
      programs: [],
      slotItems: [],
      panel: [],
      disabled: false,
      ServerError: false,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu11: false,
      menu1: false,
      menu2: false,
      summaryList: null,
      json_fields: {
        TicketNo: "ticketNo",
        ProgrameName: "programmeName",
        BookingDate: "bookingDate",
        Name: "guest.name",
        // LastName: "guest.lastName",
        PhoneNumber: "guest.phoneno",
        Email: "guest.email",
        DateofBirth: "guest.dob",
        Gender: "guest.gender",
        GuestType: "guest.guestType",
        // SlotAvailable: "slotDetail.availableNo",
        StartTime: "slotDetail.startTime",
        EndTime: "slotDetail.endTime",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      headings: ["Ticket Number", "Paid Date", "Paid By", "Paid Amount"],
    };
  },
  watch: {
    fromDate() {
      this.currentPage = 1;
      this.getData();
      // this.getExcel();
    },
    toDate() {
      this.currentPage = 1;
      // if (this.fromDate)
      this.getData();
      // this.getExcel();
    },
    date() {
      this.currentPage = 1;
      this.getData();
      // this.getExcel();
    },
    programme() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
      this.getSlot();
      // this.getExcel();
    },
    slotDetail() {
      this.getData();
      // this.getExcel();
    },
  },
  beforeMount() {
    if (this.dateProp !== "dateWisePrograms") {
      this.date = null;
    }
    // if (this.$route.query.dateProp != "dateWisePrograms") this.date = null;
    // this.getData();
    this.getProgramme();
    // this.getExcel();
    if (this.date) {
      this.date.setDate(this.date.getDate() + 1);
      this.date = this.date.toISOString().substr(0, 10);
    }
    if (this.fromDate) {
      // this.fromDate = null;
      this.fromDate.setDate(this.fromDate.getDate());
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      // this.toDate = null;
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.checkDate();
  },
  methods: {
    checkDate() {
      // alert("lo")
      let newDate = new Date().toISOString().substr(0, 10);
      if (this.fromDate == newDate) {
        const tomorrow = new Date(this.fromDate);
        tomorrow.setDate(tomorrow.getDate() - 10);
        this.fromDate = tomorrow.toISOString().substr(0, 10);
        // this.toDate = this.toDate.toISOString().substr(0, 10);
      }
    },

    winStepper(windowData) {
      if (windowData.getData) {
        this.currentPage = windowData.currentPage;
        this.pages = windowData.pages;
        this.getData();
      }
    },
    getData() {
      // console.log("send",this.currentPage)
      this.appLoading = true;
      if (this.dateProp == "dateWisePrograms") {
        axios({
          method: "GET",
          url: "/booking/admingetlist",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            date: this.date,
            programme: this.programme,
            slotDetail: this.slotDetail,
            count: 12,
            page: this.currentPage,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.summaryList = response.data.summary;
              this.booking = response.data.data;
              this.reservationData = response.data.reservations;
              this.pages = response.data.pages;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        axios({
          method: "GET",
          url: "/booking/admingetlist",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            fromDate: this.fromDate,
            toDate: this.toDate,
            programme: this.programme,
            slotDetail: this.slotDetail,
            count: 12,
            page: this.currentPage,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.booking = response.data.data;
              this.reservationData = response.data.reservations;
              this.pages = response.data.pages;
              this.summaryList = response.data.summary;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    // getExcel() {
    //   this.appLoading = true;

    //   if (this.dateProp == "dateWisePrograms") {
    //     axios({
    //       method: "GET",
    //       url: "/booking/admingetlistexcel",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       params: {
    //         date: this.date,
    //         programme: this.programme,
    //         slotDetail: this.slotDetail,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.data.status) {
    //           for (var i = 0; i < response.data.data.length; i++) {
    //             response.data.data[i].bookingDate = this.formatDate(
    //               response.data.data[i].bookingDate
    //             );
    //             response.data.data[i].guest.dob = this.formatDate(
    //               response.data.data[i].guest.dob
    //             );
    //           }
    //           this.excel = response.data.data;
    //           this.json_data = this.excel;
    //           this.appLoading = false;
    //         } else {
    //           this.msg = response.data.msg;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   } else {
    //     axios({
    //       method: "GET",
    //       url: "/booking/admingetlistexcel",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       params: {
    //         fromDate: this.fromDate,
    //         toDate: this.toDate,
    //         programme: this.programme,
    //         slotDetail: this.slotDetail,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.data.status) {
    //           for (var i = 0; i < response.data.data.length; i++) {
    //             response.data.data[i].bookingDate = this.formatDate(
    //               response.data.data[i].bookingDate
    //             );
    //             response.data.data[i].guest.dob = this.formatDate(
    //               response.data.data[i].guest.dob
    //             );
    //           }
    //           this.excel = response.data.data;
    //           this.json_data = this.excel;
    //           this.appLoading = false;
    //         } else {
    //           this.msg = response.data.msg;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   }
    // },
    getProgramme() {
      this.appLoading = true;
      axios({
        url: "/programme/getlist",
        method: "GET",
        params: { programmestatus: "started" },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            var temp = [
              {
                name: null,
                value: null,
              },
            ];
            temp = response.data.data;
            for (var i = 0; i < temp.length; i++) {
              temp[i].name = temp[i].progName;
              temp[i].value = temp[i]._id;
            }
            this.programs = temp;
            this.programs.unshift({ name: "All Programs", value: null });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSlot() {
      this.appLoading = true;
      axios({
        url: "/programme/slots/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          programme: this.programme,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.slotItems = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.itemTags {
  font-family: poppinsregular;
}
.itemHeading {
  color: #8d8d8d;
  font-size: 14px;
  font-family: poppinsregular;
}
</style>